var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-breadcrumb',{attrs:{"items":_vm.items}}),_c('b-nav',[_c('b-nav-item',{staticStyle:{"position":"absolute","z-index":"2222","right":"0","top":"3.3em"},attrs:{"active":""}},[_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-folder-collection",modifiers:{"modal-folder-collection":true}}],staticClass:"mr-2",attrs:{"variant":"info"}},[_vm._v(" Add folder collection "),_c('b-icon',{staticStyle:{"float":"right"},attrs:{"icon":"plus"}})],1),_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-center",modifiers:{"modal-center":true}}],attrs:{"variant":"info"}},[_vm._v(" Add "),_c('b-icon',{staticStyle:{"float":"right"},attrs:{"icon":"plus"}})],1)],1),_c('b-nav-item',[_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-folder",modifiers:{"modal-folder":true}}],attrs:{"variant":"info"}},[_vm._v(" Add folder"),_c('b-icon',{staticStyle:{"float":"right"},attrs:{"icon":"plus"}})],1)],1)],1),(_vm.processing)?_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"height":"65vh"}},[_c('Spinner')],1):_c('div',{staticClass:"content-area"},[(_vm.rows && _vm.rows.length)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
        enabled: true,
        perPage: 7,
      },"search-options":{
        enabled: true,
        placeholder: 'Search this table',
      },"sort-options":{
        enabled: false,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_c('router-link',{staticClass:"mb-0 dataset text-info",attrs:{"to":("/admin/datasets/" + (props.row.id))}},[_vm._v(_vm._s(props.row.name))])],1):_vm._e(),(props.column.field == 'progress')?_c('span',{staticClass:"row p-0",staticStyle:{"min-width":"200px"}},[_c('p',{staticClass:"col-3 pb-0 m-0 text-info"},[_vm._v(" "+_vm._s(Math.round(props.row.progress) + "%")+" ")]),_c('div',{staticClass:"col-8 p-1 pt-2"},[_c('b-progress',{attrs:{"value":props.row.progress,"variant":"info","height":"0.5em"}})],1)]):_vm._e(),(props.column.label == 'Download')?_c('span',[_c('a',{attrs:{"href":("http://marconimlannotator/api/v1/download/csv/" + (props.row.id) + "/")}},[_c('b-icon',{staticClass:"download-icon",staticStyle:{"float":"left","font-weight":"bold","font-size":"1.3rem"},attrs:{"icon":"download","variant":"info"}})],1)]):_vm._e(),(props.column.label == 'Delete')?_c('span',[_c('b-icon',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-delete",modifiers:{"modal-delete":true}}],staticClass:"delete-icon",staticStyle:{"float":"left","font-weight":"bold","font-size":"1.3rem"},attrs:{"icon":"trash","variant":"danger"},on:{"click":function($event){_vm.selectedDataset = props.row.id}}})],1):_vm._e()]}}],null,false,1697588094)}):_vm._e()],1),_c('b-modal',{attrs:{"id":"modal-center","centered":"","title":"Modify Datasets","header-bg-variant":"info","header-text-variant":"white","footer-border-variant":"info"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref ){
      var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":function($event){return cancel()}}},[_vm._v("cancel")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":_vm.handleDatasetCreate}},[_vm._v("Submit")])]}}])},[_c('b-form-input',{attrs:{"placeholder":"Enter Dataset title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('b-form-input',{staticClass:"mt-3",attrs:{"placeholder":"Enter classes, e.g (Positive, negative, not sure)"},model:{value:(_vm.classes),callback:function ($$v) {_vm.classes=$$v},expression:"classes"}})],1),_c('b-modal',{attrs:{"id":"modal-folder-collection","centered":"","title":"Create Folder","header-bg-variant":"info","header-text-variant":"white","footer-border-variant":"info"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref ){
      var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":function($event){return cancel()}}},[_vm._v("cancel")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":_vm.handleFolderCreate}},[_vm._v("Submit")])]}}])},[_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":"Enter Dataset title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('b-form-input',{staticClass:"mt-3",attrs:{"placeholder":"Enter classes, e.g (Positive, negative, not sure)"},model:{value:(_vm.classes),callback:function ($$v) {_vm.classes=$$v},expression:"classes"}}),_c('b-form-file',{staticClass:"mt-3",attrs:{"id":"file-small","directory":"","multiple":""},model:{value:(_vm.folder),callback:function ($$v) {_vm.folder=$$v},expression:"folder"}})],1)],1),_c('b-modal',{attrs:{"id":"modal-folder","centered":"","title":"Create Folder","header-bg-variant":"info","header-text-variant":"white","footer-border-variant":"info"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref ){
      var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":function($event){return cancel()}}},[_vm._v("cancel")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":_vm.handleFolderUpload}},[_vm._v("Submit")])]}}])},[_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":"Enter Dataset title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('b-form-file',{staticClass:"mt-3",attrs:{"id":"file-small","directory":"","multiple":""},model:{value:(_vm.folder),callback:function ($$v) {_vm.folder=$$v},expression:"folder"}})],1)],1),_c('b-modal',{attrs:{"id":"modal-delete","centered":"","title":"Delete Dataset","header-bg-variant":"info","header-text-variant":"white","footer-border-variant":"info"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref ){
      var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":function($event){return cancel()}}},[_vm._v("cancel")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":_vm.handleDatasetDelete}},[_vm._v("Delete")])]}}])},[_c('h4',{staticClass:"text-danger"},[_vm._v(" You are deleting a dataset "),_c('b-icon',{attrs:{"icon":"exclamation-circle"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }